// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-accessibility-remediation-js": () => import("./../../../src/pages/accessibility-remediation.js" /* webpackChunkName: "component---src-pages-accessibility-remediation-js" */),
  "component---src-pages-accessibility-statement-js": () => import("./../../../src/pages/accessibility-statement.js" /* webpackChunkName: "component---src-pages-accessibility-statement-js" */),
  "component---src-pages-accessibility-testing-js": () => import("./../../../src/pages/accessibility-testing.js" /* webpackChunkName: "component---src-pages-accessibility-testing-js" */),
  "component---src-pages-ai-development-js": () => import("./../../../src/pages/ai-development.js" /* webpackChunkName: "component---src-pages-ai-development-js" */),
  "component---src-pages-android-penetration-testing-js": () => import("./../../../src/pages/android-penetration-testing.js" /* webpackChunkName: "component---src-pages-android-penetration-testing-js" */),
  "component---src-pages-api-penetration-testing-js": () => import("./../../../src/pages/api-penetration-testing.js" /* webpackChunkName: "component---src-pages-api-penetration-testing-js" */),
  "component---src-pages-automation-testing-js": () => import("./../../../src/pages/automation-testing.js" /* webpackChunkName: "component---src-pages-automation-testing-js" */),
  "component---src-pages-blogs-index-js": () => import("./../../../src/pages/blogs/index.js" /* webpackChunkName: "component---src-pages-blogs-index-js" */),
  "component---src-pages-careers-automation-engineer-js": () => import("./../../../src/pages/careers/automation-engineer.js" /* webpackChunkName: "component---src-pages-careers-automation-engineer-js" */),
  "component---src-pages-careers-business-developer-js": () => import("./../../../src/pages/careers/business-developer.js" /* webpackChunkName: "component---src-pages-careers-business-developer-js" */),
  "component---src-pages-careers-index-js": () => import("./../../../src/pages/careers/index.js" /* webpackChunkName: "component---src-pages-careers-index-js" */),
  "component---src-pages-careers-lead-golang-developer-js": () => import("./../../../src/pages/careers/lead-golang-developer.js" /* webpackChunkName: "component---src-pages-careers-lead-golang-developer-js" */),
  "component---src-pages-careers-salesforce-developer-js": () => import("./../../../src/pages/careers/salesforce-developer.js" /* webpackChunkName: "component---src-pages-careers-salesforce-developer-js" */),
  "component---src-pages-cloud-penetration-testing-js": () => import("./../../../src/pages/cloud-penetration-testing.js" /* webpackChunkName: "component---src-pages-cloud-penetration-testing-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-devops-js": () => import("./../../../src/pages/devops.js" /* webpackChunkName: "component---src-pages-devops-js" */),
  "component---src-pages-digital-marketing-js": () => import("./../../../src/pages/digital-marketing.js" /* webpackChunkName: "component---src-pages-digital-marketing-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-leave-calender-js": () => import("./../../../src/pages/leave-calender.js" /* webpackChunkName: "component---src-pages-leave-calender-js" */),
  "component---src-pages-manual-testing-js": () => import("./../../../src/pages/manual-testing.js" /* webpackChunkName: "component---src-pages-manual-testing-js" */),
  "component---src-pages-mobile-development-js": () => import("./../../../src/pages/mobile-development.js" /* webpackChunkName: "component---src-pages-mobile-development-js" */),
  "component---src-pages-network-penetration-testing-js": () => import("./../../../src/pages/network-penetration-testing.js" /* webpackChunkName: "component---src-pages-network-penetration-testing-js" */),
  "component---src-pages-penetration-testing-js": () => import("./../../../src/pages/penetration-testing.js" /* webpackChunkName: "component---src-pages-penetration-testing-js" */),
  "component---src-pages-performance-testing-js": () => import("./../../../src/pages/performance-testing.js" /* webpackChunkName: "component---src-pages-performance-testing-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-schedule-a-call-js": () => import("./../../../src/pages/schedule-a-call.js" /* webpackChunkName: "component---src-pages-schedule-a-call-js" */),
  "component---src-pages-secops-js": () => import("./../../../src/pages/secops.js" /* webpackChunkName: "component---src-pages-secops-js" */),
  "component---src-pages-security-testing-js": () => import("./../../../src/pages/security-testing.js" /* webpackChunkName: "component---src-pages-security-testing-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-teams-[id]-js": () => import("./../../../src/pages/teams/[id].js" /* webpackChunkName: "component---src-pages-teams-[id]-js" */),
  "component---src-pages-teams-js": () => import("./../../../src/pages/teams.js" /* webpackChunkName: "component---src-pages-teams-js" */),
  "component---src-pages-technology-stack-js": () => import("./../../../src/pages/TechnologyStack.js" /* webpackChunkName: "component---src-pages-technology-stack-js" */),
  "component---src-pages-users-aditya-sharma-js": () => import("./../../../src/pages/users/aditya_sharma.js" /* webpackChunkName: "component---src-pages-users-aditya-sharma-js" */),
  "component---src-pages-website-development-js": () => import("./../../../src/pages/website-development.js" /* webpackChunkName: "component---src-pages-website-development-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

